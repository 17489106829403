
.therapyPlansContainer {
    text-align: center; /* Center the main title */
    padding: 50px;
    background-color: #fff; /* Or any color you prefer */
    margin: 0 5rem;
  }
  
  .uniqueTitle {
    font-size: 2.5rem; /* Larger font size for the main title */
    color: #eec11f; /* Or any color you prefer */
    margin-bottom: 50px; /* Space below the main title */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    display: inline-block; /* Allows the ::before element to align with the text properly */
    padding-top: 30px;
  }

  .therapyPlansSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }
  
  .textContent {
    flex: 1; /* Take up half the width of the container */
    padding-right: 50px; /* Add some spacing between text and image */
    text-align: left;
  }
  
  .textContent h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }
  
  .textContent p {
    font-size: 1rem;
    color: #333; /* Or any color you prefer */
    line-height: 1.5;
  }
  
  .imageContent {
    flex: 1; /* Take up the other half */
    position: relative; /* For positioning the bee logo absolutely */
    width: rem;
  }

  .childImage {
    width: 90%;
    height: 30rem;
    object-fit: cover; /* To maintain aspect ratio */
    border-radius: 30px; /* Rounded corners for the image */
  }

  .certificate {
    width: 30%;
    margin: 0 auto;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .therapyPlansSection {
      flex-direction: column;
      padding: 30px;
    }
    .therapyPlansContainer {
        margin: 0;
      }
  
    .textContent {
      padding-right: 0;
      padding-bottom: 20px; /* Add some space between the text and the image */
    }
  
    .imageContent {
      width: 100%; /* Full width */
    }
    .certificate {
        width: 100%;
        margin: 0 auto;
      }
  }

  .guidanceSupportContainer {
    margin-top: 50px;
    display: flex;
    color: #000;
    padding: 20px;
    align-items: center; /* Align the image and text vertically */
    justify-content: center; /* Center the content horizontally */
  }
  
  
  .familyImage {
    width: 100%; /* Make image responsive */
    height: auto;
    border-radius: 10px; /* Rounded corners for the image */
  }
  
  .textContainer {
    flex: 1; /* Take up the remaining half */
    padding-left: 50px; /* Space between the image and the text */

  }
  
  .textContainer h2 {
    font-size: 2rem; /* Larger font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }
  
  .textContainer p {
    font-size: 1rem;
    color: #333; /* Or any color you prefer */
    line-height: 1.5;
    text-align: left;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .guidanceSupportContainer {
      flex-direction: column;
      text-align: center; /* Center text on small screens */
    }
  
    .imageContainer {
      flex-basis: auto; /* Allow image container to size automatically */
    }
  
    .textContainer {
      padding-left: 0; /* Remove padding on small screens */
      padding-top: 20px; /* Space above the text */
    }
  }

  .serviceSection {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    margin-top: 7rem;
    background-color: #fff;
  }

  .serviceSection::before {
    content: '';
    width: 80px; /* Width of the horizontal line */
    height: 4px; /* Thickness of the line */
    background-color: #f6454f; /* Color of the horizontal line */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* top: 0; */
    margin-top: -90px; /* Adjust if needed to position the line above the title */
  }

  @media (max-width: 768px) {
    .serviceSection {
      flex-direction: column;
      align-items: center; /* Center align the cards when stacked */
    }
    .serviceSection::before {
      /* Adjust the line or remove if not needed for mobile */
      margin-top: -1000px; /* Adjust this value to fit your design */
    }
  }