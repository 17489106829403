/* Services.module.css */
.services {
  text-align: center;
  padding: 40px 20px;
  margin: 3rem 10rem;
  background: #fff; /* Or any other preferred background */
}

.services h1{
  color: #eec11f;
}

.title {
  font-size: 40px;
  font-family:'Montserrat', sans-serif;
  color: #333;
}

.serviceGrid {
  display: grid;
  grid-template-columns: 30% 80%;
  gap: 30px;
  padding: 20px;
}

.mainService, .otherServices {
  text-align: left;
  padding: 7px;
  border-radius: 8px;
}

.servies, .mainService p {
  color: #666;
}

.otherServices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
.serviceItem {
  padding: 0 60px;
  text-align: left;
}

.serviceItem h2{
  font-family:'Montserrat', sans-serif;
}

.serviceItem p{
  color: #555555;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.icon {
  font-size: 40px; 
  text-align: left;
}

.contactUs{
  background-color: #eec11f;
  margin: 0px;
  padding: 0 30px;
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Centers items vertically */
  justify-content:center;
}
.button {
  font-family:'Montserrat', sans-serif;
  font-style: normal;
  font-size: 14px;
  padding: 10px 10px;
  margin-left: 20px;
  min-width: 130px;
  background-color: #ffcd18;
  border-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color .3s;
  line-height: 20px;
  }
  
  .button:hover {
  background-color: #37393a;
  color: white;
  border-color: transparent;
  }

.contactUs h1{
  color: #000000;
  font-size: 40px;
  width: fit-content;
}

.pricing {
  text-align: center;
}
.pricing h2{
  font-size: 40px;
}
.pricing p{
  font-size: 30px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .services {
    text-align: center;
    padding: 40px 20px;
    margin: 3rem 0rem;

  }

  .serviceGrid {
    grid-template-columns: 1fr; /* One column for all items */
  }

  .otherServices {
    display: grid;
    grid-template-columns: 1fr; /* Adjust other services to single column */
  }
}