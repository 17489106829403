/* Header.module.css */
.hero {
    position: relative;
    width: 100%; 
    height: 50rem;
    overflow: hidden; 
  }
  
.hero img {
width: 100%;
display: block; 
height: 100%; 
object-fit: cover;
}

.hero::after {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.5);
}

.heroContent {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: white;
text-align: center;
z-index: 2; 
text-align: left;
}

.button {
font-family:'Montserrat', sans-serif;
font-style: normal;
font-size: 14px;
margin: 10px;
padding: 10px 10px;
min-width: 130px;
background-color: #ffcd18; 
color: #262626;
border-color: transparent;
border: 1px solid;
border-radius: 5px;
cursor: pointer;
font-weight: 600;
transition: background-color .3s;
line-height: 20px;
}

.button:hover {
background-color: #37393a;
color: white;
border-color: transparent;
}

h1 {
margin-bottom: 20px;
font-family:'Montserrat', sans-serif;
font-size: 59px;
line-height: 1.1;
}

p {
margin-bottom: 20px;
line-height: 1.1;
}