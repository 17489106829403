
.contact {
    text-align: center; /* Center the main title */
    /* padding: 50px; */
    background-color: #fff; /* Or any color you prefer */
    /* margin: 0 5rem; */
}

.contact p {
  font-size: 20px;
}
  
  .uniqueTitle {
    font-size: 2.5rem; /* Larger font size for the main title */
    color: #eec11f; /* Or any color you prefer */
    margin-bottom: 50px; /* Space below the main title */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    display: inline-block; /* Allows the ::before element to align with the text properly */
    padding-top: 30px;
  }

  
  .gridItem {
    padding: 15px;
    text-align: center;
  }
  
  .icon {
    font-size: 40px;
  }
  
  .gridItem h3 {
    color: #333;
    font-size: 30px;
  }
  
  .gridItem p {
    color: #666;
    font-size: 20px;
  }

  .gridItem a{
    color: black;

  }

  @media (max-width: 768px) {
  }